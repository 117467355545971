import customer from "@/api/credit-operation/customer";
import { baseState, baseMutations } from "@/store/mixins";

import { isEmpty } from "lodash";

export const state = {
    ...baseState,
    photoBlob: "",
    spPhotoBlob: "",
    provinces: [],
    documentTypes: [],
    occupations: [],
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_PHOTO_BLOB(state, blob) {
        state.photoBlob = blob;
    },
    SET_SP_PHOTO_BLOB(state, blob) {
        state.spPhotoBlob = blob;
    },
    SET_PROVINCES(state, data) {
        state.provinces = data;
    },
    SET_DOCUMENT_TYPES(state, data) {
        state.documentTypes = data;
    },
    SET_OCCUPATIONS(state, data) {
        state.occupations = data;
    }
};

export const actions = {
    fetch(context, attributes = {}) {
        const params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return customer.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },

    store(context, data) {
        return customer.store(data);
    },

    async find(context, id) {
        if (isEmpty(context.state.edit_data)) {
            let response = await customer.find(id);
            context.commit("SET_EDIT_DATA", response.data);
        }
    },

    update(context, { id, data }) {
        return customer.update(id, data);
    },

    destroy(context, id) {
        return customer.destroy(id);
    },

    photoUpload(context, data) {
        return customer.photoUpload(data);
    },
    addVillage(context, data) {
        return customer.addVillage(data);
    },
    async getProvince(context) {
        let response = await customer.province();
        context.commit("SET_PROVINCES", response.data);
    },
    async getDocumentType(context) {
        let response = await customer.documentType();
        context.commit("SET_DOCUMENT_TYPES", response.data);
    },
    async getOccupation(context) {
        let response = await customer.occupation();
        context.commit("SET_OCCUPATIONS", response.data);
    },
    getDistrict(context, province_code) {
        if (!province_code) return;
        return customer.district(province_code);
    },
    getCommune(context, district_code) {
        if (!district_code) return;
        return customer.commune(district_code);
    },
    getVillage(context, commune_code) {
        if (!commune_code) return;
        return customer.village(commune_code);
    },
    removeFileUploaded(context, attributes) {
        return customer.removeFile({
            params: attributes
        });
    },
    exportExcel(context, payload = {}) {
        return customer.exportExcel(
            Object.assign(
                {
                    template: localStorage.getItem("langCode")
                },
                payload
            )
        );
    }
};
