import creditOperation from '@/api/report/credit-operation'
import { baseState, baseMutations } from '@/store/mixins'
import { join } from 'lodash'
export const state = {
    ...baseState
}

export const getters = {}

export const mutations = {
    ...baseMutations
}

export const actions = {
    // generate daily Collection Schedule
    dailyCollectionSchedule(context, attributes = {}) {
        return creditOperation.dailyCollectionSchedule({
            params: Object.assign(
                {
                    template: localStorage.getItem('langCode'),
                    filter: {
                        co_id: join(attributes.employee_id, ','),
                        currency_id: attributes.currency_id
                    }
                },
                attributes
            )
        })
    },
    // generate disbursement list
    disbursementList(context, attributes = {}) {
        return creditOperation.disbursementList({
            params: Object.assign(
                {
                    template: localStorage.getItem('langCode'),
                    filter: {
                        co_id: join(attributes.employee_id, ','),
                        date_between: join(attributes.dateRange, ','),
                        currency_id: attributes.currency_id
                    }
                },
                attributes
            )
        });
    },
    // generate summary Of Portfolio At Risk
    summaryOfPortfolioAtRisk(context, attributes = {}) {
        return creditOperation.summaryOfPortfolioAtRisk({
            params: Object.assign(
                {
                    template: localStorage.getItem('langCode')
                },
                attributes
            )
        })
    },
    // generate portfolio Repayment
    portfolioRepayment(context, attributes = {}) {
        return creditOperation.portfolioRepayment({
            params: Object.assign(
                {
                    template: localStorage.getItem('langCode')
                },
                attributes
            )
        })
    },
    // generate loan Overdue
    loanOverdue(context, attributes = {}) {
        return creditOperation.loanOverdue({
            params: Object.assign(
                {
                    template: localStorage.getItem('langCode')
                },
                attributes
            )
        })
    },
    // generate loan Transaction Summary
    loanTransactionSummary(context, attributes = {}) {
        return creditOperation.loanTransactionSummary({
            params: Object.assign(
                {
                    template: localStorage.getItem('langCode')
                },
                attributes
            )
        })
    },
    // generate loan Transaction Detail
    loanTransactionDetail(context, attributes = {}) {
        return creditOperation.loanTransactionDetail({
            params: Object.assign(
                {
                    template: localStorage.getItem('langCode')
                },
                attributes
            )
        })
    },
    // generate customer statement
    customerStatement(context, attributes = {}) {
        return creditOperation.customerStatement({
            params: Object.assign(
                {
                    template: localStorage.getItem('langCode')
                },
                attributes
            )
        })
    },
    // generate loan write off
    loanWriteOff(context, attributes = {}) {
        return creditOperation.loanWriteOff({
            params: Object.assign(
                {
                    template: localStorage.getItem('langCode'),
                    filter: {
                        co_id: join(attributes.employee_id, ','),
                        date_between: join(attributes.dateRange, ','),
                        currency_id: attributes.currency_id
                    }
                },
                attributes
            )
        })
    },
    // generate loan recovery
    loanRecovery(context, attributes = {}) {
        return creditOperation.loanRecovery({
            params: Object.assign(
                {
                    template: localStorage.getItem('langCode'),
                    filter: {
                        co_id: join(attributes.employee_id, ','),
                        date_between: join(attributes.dateRange, ','),
                        currency_id: attributes.currency_id
                    }
                },
                attributes
            )
        })
    },
    loanTransactionSummaryExportExcel(context, { ...attributes }) {
        return creditOperation.loanTransactionSummaryExportExcel(
            Object.assign(
                {
                    template: localStorage.getItem('langCode')
                        ? localStorage.getItem('langCode')
                        : 'en'
                },
                attributes
            )
        )
    },
    loanTransactionSummaryRawDataCSV(context, { ...attributes }) {
        return creditOperation.loanTransactionSummaryRawDataCSV(
            Object.assign(
                {
                    template: localStorage.getItem('langCode')
                        ? localStorage.getItem('langCode')
                        : 'en'
                },
                attributes
            )
        )
    },
    loanTransactionDetailExportExcel(context, { ...attributes }) {
        return creditOperation.loanTransactionDetailExportExcel(
            Object.assign(
                {
                    template: localStorage.getItem('langCode')
                        ? localStorage.getItem('langCode')
                        : 'en'
                },
                attributes
            )
        )
    },
    disbursementListExportExcel(context, { ...attributes }) {
        return creditOperation.disbursementListExportExcel(
            Object.assign(
                {
                    template: localStorage.getItem('langCode')
                        ? localStorage.getItem('langCode')
                        : 'en'
                },
                attributes
            )
        )
    },
    disbursementListRawDataCSV(context, { ...attributes }) {
        return creditOperation.disbursementListRawDataCSV(
            Object.assign(
                {
                    template: localStorage.getItem('langCode')
                        ? localStorage.getItem('langCode')
                        : 'en'
                },
                attributes
            )
        )
    },
    loanWriteOffExportExcel(context, { ...attributes }) {
        return creditOperation.loanWriteOffExportExcel(
            Object.assign(
                {
                    template: localStorage.getItem('langCode')
                        ? localStorage.getItem('langCode')
                        : 'en',
                    filter: {
                        co_id: join(attributes.employee_id, ','),
                        date_between: join(attributes.dateRange, ','),
                        currency_id: attributes.currency_id
                    }
                },
                attributes
            )
        )
    },
    loanRecoveryExportExcel(context, { ...attributes }) {
        return creditOperation.loanRecoveryExportExcel(
            Object.assign(
                {
                    template: localStorage.getItem('langCode')
                        ? localStorage.getItem('langCode')
                        : 'en',
                    filter: {
                        co_id: join(attributes.employee_id, ','),
                        date_between: join(attributes.dateRange, ','),
                        currency_id: attributes.currency_id
                    }
                },
                attributes
            )
        )
    },
    loanRecoveryRawDataCSV(context, { ...attributes }) {
        return creditOperation.loanRecoveryRawDataCSV(
            Object.assign(
                {
                    template: localStorage.getItem('langCode')
                        ? localStorage.getItem('langCode')
                        : 'en',
                    filter: {
                        co_id: join(attributes.employee_id, ','),
                        date_between: join(attributes.dateRange, ','),
                        currency_id: attributes.currency_id
                    }
                },
                attributes
            )
        )
    },
    // generate loan drop out
    loanDropOut(context, attributes = {}) {
        return creditOperation.loanDropOut({
            params: Object.assign(
                {
                    template: localStorage.getItem('langCode'),
                    filter: {
                        co_id: join(attributes.employee_id, ','),
                        date_between: join(attributes.dateRange, ','),
                        currency_id: attributes.currency_id
                    }
                },
                attributes
            )
        })
    },
    loanDropOutRawDataCSV(context, { ...attributes }) {
        return creditOperation.loanDropOutRawDataCSV(
            Object.assign(
                {
                    template: localStorage.getItem('langCode')
                        ? localStorage.getItem('langCode')
                        : 'en'
                },
                attributes
            )
        )
    },
    loanDropOutExportExcel(context, { ...attributes }) {
        return creditOperation.loanDropOutExportExcel(
            Object.assign(
                {
                    template: localStorage.getItem('langCode')
                        ? localStorage.getItem('langCode')
                        : 'en',
                    filter: {
                        co_id: join(attributes.employee_id, ','),
                        date_between: join(attributes.dateRange, ','),
                        currency_id: attributes.currency_id
                    }
                },
                attributes
            )
        )
    },
    customerStatementExportExcel(context, { ...attributes }) {
        return creditOperation.customerStatementExportExcel(
            Object.assign(
                {
                    template: localStorage.getItem('langCode')
                        ? localStorage.getItem('langCode')
                        : 'en'
                },
                attributes
            )
        )
    },
    customerStatementRawDataCSV(context, { ...attributes }) {
        return creditOperation.customerStatementRawDataCSV(
            Object.assign(
                {
                    template: localStorage.getItem('langCode')
                        ? localStorage.getItem('langCode')
                        : 'en'
                },
                attributes
            )
        )
    },
    dailyCollectionScheduleExportExcel(context, { ...attributes }) {
        return creditOperation.dailyCollectionScheduleExportExcel(
            Object.assign(
                {
                    template: localStorage.getItem('langCode'),
                    filter: {
                        co_id: join(attributes.employee_id, ','),
                        currency_id: attributes.currency_id
                    }
                },
                attributes
            )
        )
    },
    dailyCollectionScheduleRawDataCSV(context, { ...attributes }) {
        return creditOperation.dailyCollectionScheduleRawDataCSV(
            Object.assign(
                {
                    template: localStorage.getItem('langCode'),
                    filter: {
                        co_id: join(attributes.employee_id, ','),
                        currency_id: attributes.currency_id
                    }
                },
                attributes
            )
        )
    },
    summaryOfPortfolioAtRiskExportExcel(context, { ...attributes }) {
        return creditOperation.summaryOfPortfolioAtRiskExportExcel(
            Object.assign(
                {
                    template: localStorage.getItem('langCode'),
                    filter: {
                        co_id: join(attributes.employee_id, ','),
                        currency_id: attributes.currency_id
                    }
                },
                attributes
            )
        )
    },
    summaryOfPortfolioAtRiskRawDataCSV(context, { ...attributes }) {
        return creditOperation.summaryOfPortfolioAtRiskRawDataCSV(
            Object.assign(
                {
                    template: localStorage.getItem('langCode'),
                    filter: {
                        co_id: join(attributes.employee_id, ','),
                        currency_id: attributes.currency_id
                    }
                },
                attributes
            )
        )
    },
    portfolioRepaymentExportExcel(context, { ...attributes }) {
        return creditOperation.portfolioRepaymentExportExcel(
            Object.assign(
                {
                    template: localStorage.getItem('langCode')
                },
                attributes
            )
        )
    },
    portfolioRepaymentRawDataCSV(context, { ...attributes }) {
        return creditOperation.portfolioRepaymentRawDataCSV(
            Object.assign(
                {
                    template: localStorage.getItem('langCode')
                },
                attributes
            )
        )
    },
    loanOverdueExportExcel(context, { ...attributes }) {
        return creditOperation.loanOverdueExportExcel(
            Object.assign(
                {
                    template: localStorage.getItem('langCode')
                },
                attributes
            )
        )
    },
    loanOverdueRawDataCSV(context, { ...attributes }) {
        return creditOperation.loanOverdueRawDataCSV(
            Object.assign(
                {
                    template: localStorage.getItem('langCode')
                },
                attributes
            )
        )
    },
    // generate loan active by co
    loanActiveByCo(context, attributes = {}) {
        return creditOperation.loanActiveByCo({
            params: Object.assign(
                {
                    template: localStorage.getItem('langCode')
                },
                attributes
            )
        })
    },
    loanPaidOffByCo(context, attributes = {}) {
        return creditOperation.loanPaidOffByCo({
            params: Object.assign(
                {
                    template: localStorage.getItem('langCode')
                },
                attributes
            )
        })
    },
    loanActiveByCoExportExcel(context, { ...attributes }) {
        return creditOperation.loanActiveByCoExportExcel(
            Object.assign(
                {
                    template: localStorage.getItem('langCode')
                },
                attributes
            )
        )
    },
    loanActiveByCoRawDataCSV(context, { ...attributes }) {
        return creditOperation.loanActiveByCoRawDataCSV(
            Object.assign(
                {
                    template: localStorage.getItem('langCode')
                },
                attributes
            )
        )
    },
    loanPaidOffByCoExportExcel(context, { ...attributes }) {
        return creditOperation.loanPaidOffByCoExportExcel(
            Object.assign(
                {
                    template: localStorage.getItem('langCode')
                },
                attributes
            )
        )
    },
    // generate loan incentivePerformaceByCo
    incentivePerformanceByCo(context, attributes = {}) {
        return creditOperation.incentivePerformanceByCo({
            params: Object.assign(
                {
                    template: localStorage.getItem('langCode')
                },
                attributes
            )
        })
    },
    incentivePerformanceByCoExportExcel(context, { ...attributes }) {
        return creditOperation.incentivePerformanceByCoExportExcel(
            Object.assign(
                {
                    template: localStorage.getItem('langCode')
                        ? localStorage.getItem('langCode')
                        : 'en'
                },
                attributes
            )
        )
    },
    devicePostingLogExportExcel(context, { ...attributes }) {
        return creditOperation.devicePostingLogExportExcel(
            Object.assign(
                {
                    template: localStorage.getItem('langCode')
                        ? localStorage.getItem('langCode')
                        : 'en'
                },
                attributes
            )
        )
    },
    // generate re-disbursement lead
    reDisbursementLead(context, attributes = {}) {
        return creditOperation.reDisbursementLead({
            params: Object.assign(
                {
                    template: localStorage.getItem('langCode')
                },
                attributes
            )
        })
    },
    reDisbursementLeadExportExcel(context, { ...attributes }) {
        return creditOperation.reDisbursementLeadExportExcel(
            Object.assign(
                {
                    template: localStorage.getItem('langCode')
                },
                attributes
            )
        )
    },
    reDisbursementLeadRawDataCSV(context, { ...attributes }) {
        return creditOperation.reDisbursementLeadRawDataCSV(
            Object.assign(
                {
                    template: localStorage.getItem('langCode')
                },
                attributes
            )
        )
    },
}
